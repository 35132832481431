import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import { mapEdgesToNodes } from "../../lib/helpers";
import SliderOverflow from "../Slider/SliderOverflow";

const ValueProps = ({ className }) => {
  const data = useStaticQuery(graphql`
    {
      industries: allSanityIndustries(sort: { fields: [order], order: ASC }) {
        edges {
          node {
            title
            slug {
              current
            }
            heroImage {
              asset {
                gatsbyImageData(layout: CONSTRAINED, width: 320)
              }
            }
          }
        }
      }
    }
  `);

  const industryNodes =
    data && data.industries && mapEdgesToNodes(data.industries);

  return (
    <section
      id="industries-we-serve"
      className={`relative overflow-x-hidden pb-20 md:pb-32 ${className}`}
    >
      <div className="container">
        <header className="mb-10 max-w-2xl md:mb-16">
          <h2>Industries We Serve</h2>
          <p>
            While your business is unique, it's unlikely that you're in an
            industry we haven't served. Below are a few of the industries we
            have deep experience with.
          </p>
        </header>

        <SliderOverflow>
          {industryNodes.map(
            (item, i) =>
              item.heroImage && (
                <div key={i} className="relative">
                  {item.slug ? (
                    <AniLink
                      fade
                      to={`/${item.slug.current}/`}
                      className="group no-underline"
                    >
                      <div className="overflow-hidden rounded-4xl">
                        <GatsbyImage
                          image={item.heroImage.asset.gatsbyImageData}
                          className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                        />
                      </div>
                      <div className="absolute bottom-4 left-5 mb-0 text-lg font-bold text-white md:left-8 md:bottom-8 md:text-3xl">
                        {item.title}
                      </div>
                    </AniLink>
                  ) : (
                    <>
                      <div className="overflow-hidden rounded-4xl">
                        <GatsbyImage
                          image={item.heroImage.asset.gatsbyImageData}
                          className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                        />
                      </div>
                      <div className="absolute bottom-4 left-5 mb-0 text-lg font-bold text-white md:left-8 md:bottom-8 md:text-3xl">
                        {item.title}
                      </div>
                    </>
                  )}
                </div>
              )
          )}
        </SliderOverflow>
      </div>
    </section>
  );
};

export default ValueProps;
